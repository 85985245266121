import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment';
import 'moment/locale/ru';
import classNames from "classnames";
import { detect } from 'detect-browser';

import config from '../../constants';

import s from './Track.module.scss';
import sOrder from '../Order/Order.module.scss';
import Input from '../../components/input/Input';
import Button from '../../components/button/Button';
import getMetro from '../../utils/get-metro';
import rentTimeOptions from "../../utils/rent-time";
import Header from "../../components/header/Header";

moment.locale('ru');

function TrackPage(props) {
    const params = qs.parse(props.location.search);
    const browser = detect();

    const [track, setTrack] = useState(params.t);
    const [inputTrack, setInputTrack] = useState(params.t);
    const [orderData, setOrderData] = useState({});
    const [metroStations, setMetroStations] = useState([]);
    const [needToCancelOrder, cancelOrder] = useState(false);
    const [modalStage, setModalStage] = useState(0);

    const history = useHistory();

    useEffect(() => {
        if (track) {
            fetch(`${config.API_URL}/api/v1/orders/track?t=${track}`, { method: 'GET' })
                .then(response => response.json())
                .then(response => setOrderData(response.order))
                .catch(err => console.error(err));
        }
    }, [track]);

    useEffect(() => {
        if (needToCancelOrder) {
            fetch(`${config.API_URL}/api/v1/orders/cancel?t=${track}`, { method: 'PUT' })
                .then(response => console.log('[Canceled]'))
                .catch(err => console.error(err));
        }
    }, [needToCancelOrder])

    useEffect(() => {
        getMetro()
            .then(r => setMetroStations(r))
            .catch(err => console.error(err));
    }, [])

    const { metroStation: metroId } = orderData || {};
    const targetMetro = metroStations[metroId] || {};
    const todayDate = moment().minute(59).hour(23);
    let deliveryDate, rentTime, courierName;

    if (orderData && orderData.courierFirstName) {
        courierName = orderData.courierFirstName;

        if (courierName.length > 5) {
            courierName = `${courierName[0]}${courierName[1]}...`;
        }
    }

    if (orderData && orderData.deliveryDate) {
        deliveryDate = moment(orderData.deliveryDate);
    }

    if (orderData && orderData.rentTime) {
        rentTime = rentTimeOptions.find(r => r.value === orderData.rentTime) || {};
    }

    const colors = [];
    if (orderData && orderData.color) {
        orderData.color.forEach(c => {
            switch (c) {
                case 'BLACK':
                    colors.push('чёрный жемчуг');
                    break;
                case 'GREY':
                    colors.push('серая безысходность');
                    break;
                default:
                    break;
            }
        })

        if (!colors.length) {
            colors.push('любой');
        }
    }

    const needToShowOrderInfo = !!Object.keys(orderData || {}).length;
    const status = (orderData && orderData.status) || 0;
    const overtime = (deliveryDate ? deliveryDate.diff(todayDate) < 0 : false) && status < 2;

    const rentEndDate = deliveryDate && deliveryDate.add(rentTime.value, 'd');

    const remainingTime = () => {
        if (moment().diff(rentEndDate) > 0) {
            return 'Скоро курьер заберёт самокат';
        }

        if (status >= 2) {
            return `Аренда закончится ${rentEndDate.calendar().toLowerCase()}`
        }

        return 'Пока не закончится аренда';
    }

    const orderInfo = () => (
        <div className={classNames(s.OrderColumns, { [s.Straight]: browser && browser.name === 'yandexbrowser' })}>
            <div className={s.OrderInfo}>
                {
                    needToShowOrderInfo
                        ? (
                            <>
                                <div className={s.Row}>
                                    <div className={s.Title}>Имя</div>
                                    <div className={s.Value}>{orderData.firstName}</div>
                                </div>
                                <div className={s.Row}>
                                    <div className={s.Title}>Фамилия</div>
                                    <div className={s.Value}>{orderData.lastName}</div>
                                </div>
                                <div className={s.Row}>
                                    <div className={s.Title}>Адрес</div>
                                    <div className={s.Value}>{orderData.address}</div>
                                </div>
                                <div className={s.Row}>
                                    <div className={s.Title}>Станция метро</div>
                                    <div className={s.Value}>
                                        <span className={s.Circle} style={{ backgroundColor: targetMetro.color }}/>
                                        {targetMetro.name}
                                    </div>
                                </div>
                                <div className={s.Row}>
                                    <div className={s.Title}>Телефон</div>
                                    <div className={s.Value}>{orderData.phone}</div>
                                </div>
                                <div className={s.Separator}/>
                                <div className={s.Row}>
                                    <div className={s.Title}>Дата доставки</div>
                                    <div
                                        className={s.Value}>{deliveryDate ? deliveryDate.format('D MMMM') : null}</div>
                                </div>
                                <div className={s.Row}>
                                    <div className={s.Title}>Срок аренды</div>
                                    <div className={s.Value}>{rentTime && rentTime.label}</div>
                                </div>
                                <div className={s.Separator}/>
                                <div className={s.Row}>
                                    <div className={s.Title}>Цвет</div>
                                    <div className={s.Value}>{colors.join(', ')}</div>
                                </div>
                                {
                                    orderData.comment
                                        ? (
                                            <div className={s.Row}>
                                                <div className={s.Title}>Комментарий</div>
                                                <div className={s.Value}>{orderData.comment}</div>
                                            </div>
                                        )
                                        : null
                                }

                                <br/>
                                <br/>
                                <br/>
                                {
                                    status === 0
                                        ? (
                                            <Button onClick={() => setModalStage(1)} inverted middle>Отменить заказ</Button>
                                        )
                                        : null
                                }
                            </>
                        )
                        : null
                }
            </div>
            <div className={s.OrderRoadmap}>
                <div className={classNames(s.OrderBrick, {
                    [s.Highlight]: status === 0 && !overtime,
                    [s.Overtime]: overtime && status === 0
                })}>
                    <div className={s.OrderCircle}>
                        {status > 0 ? '✓' : 1}
                    </div>
                    <div className={s.OrderInfo}>
                        <div
                            className={s.Order}>{overtime && status === 0 ? 'Курьер задерживается' : 'Самокат на складе'}</div>
                        <div className={s.OrderDescription}>
                            {
                                overtime && status === 0
                                    ? 'Не успеем привезти самокат вовремя. Чтобы уточнить статус заказа, позвоните в поддержку: 0101'
                                    : 'Скоро курьер заберет его'
                            }
                        </div>
                    </div>
                </div>
                <div className={classNames(s.OrderBrick, {
                    [s.Highlight]: status === 1,
                    [s.Overtime]: overtime && status === 1
                })}>
                    <div className={s.OrderCircle}>
                        {status > 1 ? '✓' : 2}
                    </div>
                    <div className={s.OrderInfo}>
                        <div
                            className={s.Order}>{overtime && status === 1 ? 'Курьер задерживается' : `Курьер ${courierName || ''} едет к вам`}</div>
                        <div className={s.OrderDescription}>
                            {
                                overtime && status === 1
                                    ? 'Не успеем привезти самокат вовремя. Чтобы уточнить статус заказа, позвоните в поддержку: 0101'
                                    : 'Номер для связи: 0101'
                            }
                        </div>
                    </div>
                </div>
                <div className={classNames(s.OrderBrick, { [s.Highlight]: false })}>
                    <div className={s.OrderCircle}>
                        {status >= 2 ? '✓' : 3}
                    </div>
                    <div className={s.OrderInfo}>
                        <div className={s.Order}>Курьер на месте</div>
                        <div className={s.OrderDescription}>
                            Заберите самокат и оплатите аренду
                        </div>
                    </div>
                </div>
                <div className={classNames(s.OrderBrick, { [s.Highlight]: status === 2 })}>
                    <div className={classNames(s.OrderCircle, { [s.Lineless]: status < 3 })}>
                        {(moment().diff(rentEndDate) > 0 && status > 2) ? '✓' : 4}
                    </div>
                    <div className={s.OrderInfo}>
                        <div className={s.Order}>
                            {
                                moment().diff(rentEndDate) > 0
                                    ? 'Время аренды кончилось'
                                    : 'Ну всё, теперь кататься'
                            }
                        </div>
                        <div className={s.OrderDescription}>
                            {remainingTime()}
                        </div>
                    </div>
                </div>
                {
                    status >= 3
                        ? (
                            <div className={classNames(s.OrderBrick, { [s.Highlight]: true })}>
                                <div className={classNames(s.OrderCircle, s.Lineless)}>
                                    5
                                </div>
                                <div className={s.OrderInfo}>
                                    <div className={s.Order}>Время аренды кончилось</div>
                                    <div className={s.OrderDescription}>
                                        {remainingTime()}
                                    </div>
                                </div>
                            </div>
                        )
                        : null
                }
            </div>
        </div>
    )

    const isTrackValid = track && Object.keys(orderData || {}).length;

    return (
        <>
            <Header flipped={status === 1}/>
            <div className={s.Content}>
                <div className={s.Form}>
                    <Input classNames={s.Input} middle onChange={setInputTrack} value={inputTrack}/>
                    <Button middle onClick={() => {
                        history.push(`/track?t=${inputTrack}`);
                        setTrack(inputTrack);
                    }}>
                        Посмотреть
                    </Button>
                </div>
                {
                    isTrackValid ? orderInfo() : (
                        <div className={s.NotFound}>
                            <img src={`${process.env.PUBLIC_URL}/assets/not-found.png`}
                                 alt="Not found"/>
                        </div>)
                }
                {
                    modalStage === 1
                        ? (<>
                            <div className={sOrder.Overlay}/>
                            <div className={sOrder.Modal}>
                                <div className={sOrder.ModalHeader}>
                                    Хотите отменить заказ?
                                    <br/>
                                    <div className={sOrder.Text}>&nbsp;</div>
                                </div>
                                <div className={sOrder.Buttons} style={{ marginTop: 50 }}>
                                    <Button onClick={() => setModalStage(0)} middle>Назад</Button>
                                    <Button onClick={() => {
                                        cancelOrder(true);
                                        setTimeout(() => setModalStage(2), 300)
                                    }} inverted middle>Отменить</Button>
                                </div>
                            </div>
                        </>)
                        : null
                }
                {
                    modalStage === 2
                        ? (<>
                            <div className={sOrder.Overlay}/>
                            <div className={classNames(sOrder.Modal, sOrder.Inverted)}>
                                <div className={sOrder.ModalHeader}>
                                    Заказ отменён
                                    <br/>
                                    <div className={sOrder.Text}>Возвращайтесь, мы всегда вас ждём :)</div>
                                </div>
                                <div className={sOrder.NextButton}>
                                    <Button onClick={() => history.push('/')} middle>Хорошо</Button>
                                </div>
                            </div>
                        </>)
                        : null
                }
            </div>
        </>
    );
}

export default withRouter(TrackPage);