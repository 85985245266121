import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import s from './Input.module.scss'

export default function Input(props) {
    const [pristine, setPristine] = useState(true)

    const needToShowError = props.error && (props.forceCheck || !pristine);

    if (props.multiline) {
        return (
            <div className={s.InputContainer}>
                <textarea
                    className={classNames(s.Input, props.classNames, { [s.Filled]: !!props.value })}
                    value={props.value}
                    onBlur={() => {
                        props.onBlur();
                    }}
                    onChange={(e) => {
                        props.onChange(e.target.value);
                    }}
                    placeholder={props.placeholder}
                >
                </textarea>
            </div>
        );
    }

    return (
        <div className={s.InputContainer}>
            <input
                type={props.type}
                className={classNames(s.Input, props.classNames, {
                    [s.Filled]: !!props.value,
                    [s.Error]: needToShowError,
                    [s.Responsible]: !props.irresponsible,
                })}
                value={props.value}
                onChange={(e) => {
                    props.onChange(e.target.value);
                }}
                onBlur={() => {
                    props.onBlur();

                    setPristine(false);
                }}
                placeholder={`${props.required ? '* ' : ''}${props.placeholder}`}
            />
            <div
                className={classNames({
                    [s.ErrorMessage]: true,
                    [s.Visible]: needToShowError
                })}
            >
                {props.errorMessage}
            </div>
        </div>
    )
}

Input.propTypes = {
    classNames: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,
    onBlur: PropTypes.func,
    forceCheck: PropTypes.bool,
    multiline: PropTypes.bool,
    irresponsible: PropTypes.bool,
}

Input.defaultProps = {
    classNames: '',
    type: 'text',
    required: false,
    placeholder: '',
    errorMessage: 'Тут что-то не так',
    onBlur: () => null,
    forceCheck: false,
    multiline: false,
    irresponsible: false,
}