import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import s from './Header.module.scss';
import Button from "../button/Button";
import Input from "../input/Input";

export default function Header(props) {
    const history = useHistory();

    const [showSearch, setShowSearch] = useState(false);
    const [track, setTrack] = useState('');

    return (
        <div
            className={classNames(s.Header, {
                [s.ShowSearch]: showSearch,
                [s.Flipped]: props.flipped
            })}
            style={props.style}
        >
            <div className={s.Logo}>
                <a target="_blank" rel="noopener noreferrer" href="//yandex.ru" className={s.LogoYandex}>
                    <img src={process.env.PUBLIC_URL + '/assets/ya.svg'} alt="Yandex"/>
                </a>
                <Link to="/" className={s.LogoScooter}>
                    <img src={process.env.PUBLIC_URL + '/assets/scooter.svg'} alt="Scooter"/>
                </Link>
                <div className={s.Disclaimer}>Учебный тренажер</div>
            </div>
            <div className={s.Nav}>
                {props.buttonLess ? null : <Button onClick={() => history.push('/order')}>Заказать</Button>}
                <button onClick={() => setShowSearch(true)} className={s.Link}>
                    Статус заказа
                </button>
            </div>
            <div className={s.SearchInput}>
                <Input irresponsible classNames={s.Input} placeholder="Введите номер заказа" onChange={setTrack} value={track}/>
                <Button className={s.Button} onClick={() => history.push(`/track?t=${track}`)}>Go!</Button>
            </div>
        </div>
    )
}

Header.propTypes = {
    buttonLess: PropTypes.bool,
    flipped: PropTypes.bool,
};

Header.defaultProps = {
    buttonLess: false,
    flipped: false,
};