const rentTimeOptions = [
    {value: 1, label: 'сутки'},
    {value: 2, label: 'двое суток'},
    {value: 3, label: 'трое суток'},
    {value: 4, label: 'четверо суток'},
    {value: 5, label: 'пятеро суток'},
    {value: 6, label: 'шестеро суток'},
    {value: 7, label: 'семеро суток'},
];

export default rentTimeOptions;