import React, { useEffect, useState } from 'react';
import SelectSearch from 'react-select-search';
import classNames from 'classnames';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import Dropdown from 'react-dropdown';
import { useHistory } from 'react-router-dom';
import ru from 'date-fns/locale/ru';
import { detect } from 'detect-browser';

import 'react-dropdown/style.css';
import 'react-datepicker/dist/react-datepicker.css'
import '../../lib/select-search.scss';
import '../../lib/dropdown.scss';

import config from '../../constants';
import getMetro from '../../utils/get-metro';

import s from './Order.module.scss'
import sInput from './../../components/input/Input.module.scss';
import Input from "../../components/input/Input";
import Button from "../../components/button/Button";
import Checkbox from "../../components/checkbox/Checkbox";
import rentTimeOptions from "../../utils/rent-time";
import Header from "../../components/header/Header";

registerLocale('ru', ru);
setDefaultLocale('ru');

const states = {
    CONTACTS: 0,
    ORDER_INFO: 1,
    CONFIRM: 2,
    SHOW_STATUS: 3
}

const inputValid = {
    firstName: {
        regex: /^[а-яё\s]+[a-z]?[а-яё\s]+$/i,
        min: 2,
        max: 15
    },
    lastName: {
        regex: /^[а-яё]+$/i,
        min: 2
    },
    address: {
        regex: /^[а-яё\s.0-9-,]+$/i,
        min: 5,
        max: 49,
        allowEmpty: true,
    },
    phone: {
        regex: /^\+?[0-9]+$/i,
        min: 11,
        max: 13
    }
}

const validate = (input, rules) => {
    let anyError = false;

    if (!input && rules.allowEmpty) {
        return false;
    }

    if (rules.regex && !rules.regex.test(input)) {
        anyError = true;
    }

    if (rules.min && input.length < rules.min) {
        anyError = true;
    }

    if (rules.max && input.length > rules.max) {
        anyError = true;
    }

    return anyError;
}

export default function OrderPage() {
    const browser = detect();

    const [stage, setStage] = useState(states.CONTACTS)
    const [forceCheck, setForceCheck] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [metroStation, setMetroStation] = useState(null)
    const [phone, setPhone] = useState('');

    const [startDate, setStartDate] = useState(null);
    const [rentTime, setRentTime] = useState('')
    const [blackColor, setBlackColor] = useState(false);
    const [greyColor, setGreyColor] = useState(false);
    const [comment, setComment] = useState('');

    const [track, setTrack] = useState('');
    const [needToCreateOrder, createOrder] = useState(false);

    const [metroStations, setMetroStations] = useState([]);
    const history = useHistory();

    useEffect(() => {
        if (needToCreateOrder) {
            const color = [];
            if (blackColor) {
                color.push('BLACK')
            }
            if (greyColor) {
                color.push('GREY');
            }

            fetch(`${config.API_URL}/api/v1/orders`, {
                method: 'POST',
                body: JSON.stringify({
                    firstName,
                    lastName,
                    address,
                    metroStation,
                    phone,
                    rentTime: rentTime.value,
                    deliveryDate: startDate,
                    comment,
                    color,
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => response.json())
                .then(response => setTrack(response.track))
                .catch(err => console.error(err));
        }
    }, [needToCreateOrder])

    useEffect(() => {
        getMetro()
            .then(r => setMetroStations(r))
            .catch(err => console.error(err));
    }, [])

    const nextStage = () => {
        setStage(stage === states.SHOW_STATUS ? states.SHOW_STATUS : stage + 1);
    }
    const prevStage = () => {
        setStage(stage === states.CONTACTS ? states.CONTACTS : stage - 1);
    }

    const finish = () => {
        if (browser && browser.name === 'chrome') {
            return false;
        }

        createOrder(true);
        nextStage();
    }

    const checkContacts = () => {


        if (validate(firstName, inputValid.firstName)
            || validate(lastName, inputValid.lastName)
            || validate(address, inputValid.address)
            || validate(phone, inputValid.phone)
            || !metroStation) {
            setForceCheck(true);
            return;
        }

        return nextStage();
    }

    const checkInfo = () => {
        if (!startDate || !rentTime) {
            return;
        }

        return nextStage();
    }

    const getContent = () => {
        switch (stage) {
            case states.CONTACTS:
                return (
                    <div className={s.Content}>
                        <div className={s.Header}>Для кого самокат</div>
                        <div className={s.Form}>
                            <Input
                                onChange={setFirstName}
                                value={firstName}
                                placeholder="Имя"
                                required
                                error={validate(firstName, inputValid.firstName)}
                                forceCheck={forceCheck}
                                errorMessage="Введите корректное имя"
                            />
                            <Input
                                onChange={setLastName}
                                value={lastName}
                                placeholder="Фамилия"
                                required
                                error={validate(lastName, inputValid.lastName)}
                                forceCheck={forceCheck}
                                errorMessage="Введите корректную фамилию"
                            />
                            <Input
                                onChange={setAddress}
                                value={address}
                                placeholder="Адрес: куда привезти заказ"
                                errorMessage="Введите корректный адрес"
                                required
                                onBlur={() => setAddress(`${address}`.trim())}
                                error={validate(address, inputValid.address)}
                                forceCheck={forceCheck}
                            />
                            <div className={classNames({
                                [s.Filled]: !!metroStation,
                                [s.UnderError]: !metroStation && forceCheck
                            })}>
                                <SelectSearch
                                    options={metroStations}
                                    search
                                    placeholder="* Станция метро"
                                    value={metroStation}
                                    onChange={setMetroStation}
                                    renderOption={(optionsProps, optionsData, optionSnapshot, className) => (
                                        <button {...optionsProps} key={optionsData.value}
                                                className={classNames(s.SelectOption, className)}>
                                            <div className={s.Circle} style={{ backgroundColor: optionsData.color }}/>
                                            <div className={s.Text}>{optionsData.name}</div>
                                        </button>
                                    )}
                                />
                                {
                                    !metroStation && forceCheck
                                        ? (<div className={s.MetroError}>Выберите станцию</div>)
                                        : null
                                }
                            </div>
                            <Input
                                onChange={setPhone}
                                value={phone}
                                placeholder="Телефон: на него позвонит курьер"
                                required
                                error={validate(phone, inputValid.phone)}
                                forceCheck={forceCheck}
                                errorMessage="Введите корректный номер"
                            />
                        </div>
                        <div className={s.NextButton}>
                            <Button middle onClick={checkContacts}>Далее</Button>
                        </div>
                    </div>
                );
            case states.ORDER_INFO:
            case states.CONFIRM:
            case states.SHOW_STATUS:
                return (
                    <div className={s.Content}>
                        <div className={s.Header}>Про аренду</div>
                        <div className={s.Form}>
                            <div className={s.MixedDatePicker}>
                                <DatePicker
                                    popperPlacement="bottom-right"
                                    locale="ru"
                                    dateFormat="dd.MM.yyyy"
                                    selected={startDate}
                                    onChange={date => setStartDate(date)}
                                    className={classNames(sInput.Input, sInput.Responsible, { [sInput.Filled]: !!startDate })}
                                    placeholderText="* Когда привезти самокат"
                                />
                                <img className={s.Icon}
                                     src={process.env.PUBLIC_URL + `/assets/calendar${startDate ? '' : '-passive'}.svg`}
                                     alt=""/>
                            </div>
                            <Dropdown
                                options={rentTimeOptions}
                                value={rentTime}
                                className={classNames({ [s.FilledDate]: !!rentTime })}
                                onChange={setRentTime}
                                placeholder="* Срок аренды"
                            />
                            <div className={classNames(s.Checkboxes, { [s.FilledContainer]: blackColor || greyColor })}>
                                <div className={s.Title}>Цвет самоката</div>
                                <Checkbox
                                    id="black"
                                    label="чёрный жемчуг"
                                    checked={blackColor}
                                    onChange={() => setBlackColor(!blackColor)}
                                /><br/>
                                <Checkbox
                                    id="grey"
                                    label="серая безысходность"
                                    checked={greyColor}
                                    onChange={() => setGreyColor(!greyColor)}
                                />
                            </div>
                            <Input
                                onChange={setComment}
                                value={comment}
                                placeholder="Комментарий для курьера"
                            />
                        </div>
                        <div className={s.Buttons}>
                            <Button middle inverted onClick={prevStage}>Назад</Button>
                            <Button middle onClick={checkInfo}>Заказать</Button>
                        </div>

                        {stage === states.CONFIRM ? (
                            <>
                                <div className={s.Overlay}/>
                                <div className={s.Modal}>
                                    <div className={s.ModalHeader}>
                                        Хотите оформить заказ?
                                        <br/>
                                        <div className={s.Text}>&nbsp;</div>
                                    </div>
                                    <div className={s.Buttons}>
                                        <Button onClick={prevStage} inverted middle>Нет</Button>
                                        <Button onClick={finish} middle>Да</Button>
                                    </div>
                                </div>
                            </>
                        ) : null}

                        {stage === states.SHOW_STATUS ? (
                            <>
                                <div className={s.Overlay}/>
                                <div className={s.Modal}>
                                    <div className={s.ModalHeader}>
                                        Заказ оформлен
                                        <br/>
                                        <div className={s.Text}>
                                            Номер заказа: {track}.
                                            &nbsp;Запишите его: <br/>пригодится, чтобы отслеживать статус
                                        </div>
                                    </div>
                                    <div className={s.NextButton}>
                                        <Button onClick={() => history.push(`/track?t=${track}`)} middle>Посмотреть
                                            статус</Button>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                );

            default:
                break;
        }
    }

    const content = getContent();

    if (content) {
        return (
            <>
                <Header flipped={stage === states.CONFIRM}/>
                {content}
            </>
        );
    }

    return (<div>Ты как суда попал?</div>)
}