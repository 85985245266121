import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import { useHistory } from 'react-router-dom';

import s from './Home.module.scss';
import Header from "../../components/header/Header";
import Button from "../../components/button/Button";

export default function HomePage() {
    const [opacity, setOpacity] = useState(1);
    const [offsetTop, setOffsetTop] = useState(0);
    const [showFull, setShowFull] = useState(false);

    const history = useHistory();

    useEffect(() => {
        const newImg = new Image();
        const timeout = setTimeout(() => {
            console.error(new Error('Cannot download loader.jpg'));
        }, 3000);

        newImg.onload = function () {
            clearTimeout(timeout);
            setShowFull(true);
        };

        newImg.src = `${process.env.PUBLIC_URL}/assets/loader.jpg`;

        return () => clearTimeout(timeout);
    });

    useEffect(() => {
        const handler = () => {
            const scrollTop = window.scrollY;
            const height = window.innerHeight - 80;
            const scrollTimeHeight = window.innerHeight;

            if (height <= scrollTop) {
                setOpacity(0);
            } else {
                const op = (height - scrollTop) / height;

                if (op !== 1) {
                    setOpacity(op);
                }
            }

            if (scrollTop === 0) {
                return setOpacity(1);
            }

            if (scrollTop >= scrollTimeHeight) {
                setOffsetTop(-(scrollTop - scrollTimeHeight));
            } else {
                setOffsetTop(0);
            }
        };

        window.addEventListener('scroll', handler);

        return () => {
            window.removeEventListener('scroll', handler);
        }
    });

    const minHeight = window.innerHeight - 80;

    return (
        <div className={s.HomePage}>
            <Header style={{ transform: `translateY(${offsetTop}px)` }}/>
            <div className={s.FirstPart} style={{ minHeight }}>
                {
                    showFull
                        ? (
                            <>
                                <div className={s.BluePrint} style={{ opacity, transform: `translateY(${offsetTop}px)` }}>
                                    <img src={process.env.PUBLIC_URL + '/assets/blueprint.png'} alt="Scooter blueprint"/>
                                </div>
                                <div className={s.Scooter}
                                     style={{ opacity: 1 - opacity, transform: `translateY(${offsetTop}px)` }}>
                                    <img src={process.env.PUBLIC_URL + '/assets/scooter.png'} alt="Scooter blueprint"/>
                                </div>
                            </>
                        )
                        : null
                }
                <div className={classNames(s.ArrowDown, s.Animated, s.Bounce)} style={{ opacity: Math.floor(opacity) }}>
                    <img src={process.env.PUBLIC_URL + '/assets/arrow-down.svg'} alt="Scroll down"/>
                </div>
                <div className={s.Header} style={{ transform: `translateY(${offsetTop}px)` }}>
                    Самокат <br/>на&nbsp;пару дней
                    <div
                        className={s.SubHeader}
                        style={{
                            opacity: opacity > 0.5 ? opacity : 0,
                            display: opacity > 0.5 ? 'block' : 'none'
                        }}
                    >
                        Привезём его прямо к вашей двери,<br/> а&nbsp;когда накатаетесь — заберём
                    </div>
                    <div
                        className={s.SubHeader}
                        style={{
                            opacity: (1 - opacity) > 0.5 ? (1 - opacity) : 0,
                        }}
                    >
                        Он лёгкий и с мощными колёсами— самое то, чтобы ехать по набережной и нежно барабанить пальцами
                        по рулю
                    </div>
                </div>
                <div className={s.Table} style={{ opacity: 1 - opacity, transform: `translateY(${offsetTop}px)` }}>
                    <div className={s.Row}>
                        <div className={s.Column}>&nbsp;Модель Toxic PRO</div>
                    </div>
                    {showFull ? <div className={s.Separator}/> : null}
                    <div className={classNames(s.Row, s.FirstRow)}>
                        <div className={s.Column}>Максимальная скорость</div>
                        <div className={s.Column}>40 км/ч</div>
                    </div>
                    <div className={s.Row}>
                        <div className={s.Column}>Проедет без поздарядки</div>
                        <div className={s.Column}>80 км</div>
                    </div>
                    <div className={s.Row}>
                        <div className={s.Column}>Выдерживает вес</div>
                        <div className={s.Column}>120 кг</div>
                    </div>
                    {showFull ? <div className={s.Separator}/> : null}
                </div>
            </div>
            <div className={s.SecondPart} style={{ minHeight }}>
                {/*Здесь нет контента, извините*/}
            </div>
            <div className={s.ThirdPart}>
                <div className={s.SubHeader}>
                    Как это работает
                </div>
                <div className={s.RoadMap}>
                    <div className={s.StatusBrick}>
                        <div className={s.StatusCircle}>
                            1
                        </div>
                        <div className={s.StatusInfo}>
                            <div className={s.Status}>Заказываете самокат</div>
                            <div className={s.StatusDescription}>Выбираете, когда&nbsp;и&nbsp;куда привезти
                            </div>
                        </div>
                    </div>
                    <div className={s.StatusBrick}>
                        <div className={s.StatusCircle}>
                            2
                        </div>
                        <div className={s.StatusInfo}>
                            <div className={s.Status}>Курьер привозит самокат</div>
                            <div className={s.StatusDescription}>А вы &mdash; оплачиваете аренду
                            </div>
                        </div>
                    </div>
                    <div className={s.StatusBrick}>
                        <div className={s.StatusCircle}>
                            3
                        </div>
                        <div className={s.StatusInfo}>
                            <div className={s.Status}>Катаетесь</div>
                            <div className={s.StatusDescription}>Сколько часов аренды осталось — видно на сайте
                            </div>
                        </div>
                    </div>
                    <div className={s.StatusBrick}>
                        <div className={classNames(s.StatusCircle, s.Lineless)}>
                            4
                        </div>
                        <div className={s.StatusInfo}>
                            <div className={s.Status}>Курьер забирает самокат</div>
                            <div className={s.StatusDescription}>Когда аренда заканчивается
                            </div>
                        </div>
                    </div>
                    <div className={s.FinishButton}>
                        <Button onClick={() => history.push('/order')} middle={window.outerWidth < 1920}
                                ultraBig={window.outerWidth >= 1920}>Заказать</Button>
                    </div>
                </div>
            </div>
            <div className={s.FourPart} style={{ opacity: showFull ? 1 : 0 }}>
                <div className={s.SubHeader}>Вопросы о важном</div>
                <div className={s.FAQ}>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Сколько это стоит? И как оплатить?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Сутки — 400 рублей. Оплата курьеру — наличными или картой.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Хочу сразу несколько самокатов! Так можно?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Пока что у нас так: один заказ — один самокат. Если хотите покататься с друзьями,
                                    можете просто сделать несколько заказов — один за другим.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Как рассчитывается время аренды?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Допустим, вы оформляете заказ на 8 мая. Мы привозим самокат 8 мая в течение дня.
                                    Отсчёт времени аренды начинается с момента, когда вы оплатите заказ курьеру. Если мы
                                    привезли самокат 8 мая в 20:30, суточная аренда закончится 9 мая в 20:30.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Можно ли заказать самокат прямо на сегодня?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Только начиная с завтрашнего дня. Но скоро станем расторопнее.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Можно ли продлить заказ или вернуть самокат раньше?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Пока что нет! Но если что-то срочное — всегда можно позвонить в поддержку по
                                    красивому номеру 1010.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Вы привозите зарядку вместе с самокатом?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Самокат приезжает к вам с полной зарядкой. Этого хватает на восемь суток — даже если
                                    будете кататься без передышек и во сне. Зарядка не понадобится.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Можно ли отменить заказ?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Да, пока самокат не привезли. Штрафа не будет, объяснительной записки тоже не
                                    попросим. Все же свои.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Я жизу за МКАДом, привезёте?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Да, обязательно. Всем самокатов! И Москве, и Московской области.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </div>
    );
}