import React from 'react';
import 'normalize.css';
import CookieConsent from "react-cookie-consent";
import moment from 'moment';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { detect } from 'detect-browser';

import './fonts.scss'
import s from './App.module.scss';

import HomePage from "./containers/Home/Home";
import OrderPage from "./containers/Order/Order";
import TrackPage from "./containers/Track/Track";


export default function BasicExample() {
    const browser = detect();
    const time = moment();

    console.log(`Designed by Regishka-Kartoshka 🥔 © ${time.get('year')}`);

    if (browser) {
        let [, g1, g2] = browser.version.match(/^([0-9]+)\.([0-9]+)\..*$/) || [];
        g1 = parseInt(g1, 10);
        g2 = parseInt(g2, 10);

        if (browser.name === 'ie' || (browser.name === 'yandexbrowser' && g1 && g2 && (g1 < 20 || (g1 >= 20 && g2 < 1)))) {
            return (<div>Браузер не поддерживается</div>);
        }
    }

    return (
        <Router>
            <div className={s.App}>
                <Switch>
                    <Route exact path="/">
                        <HomePage/>
                    </Route>
                    <Route path="/track">
                        <TrackPage/>
                    </Route>
                    <Route path="/order">
                        <OrderPage/>
                    </Route>
                </Switch>
                <CookieConsent
                    containerClasses={s.CookieConsent}
                    location="bottom"
                    buttonText="да все привыкли"
                    cookieName="Cartoshka"
                    buttonClasses={s.CookieButton}
                >
                    <div className={s.CookieText}>
                        И здесь куки! В общем, мы их используем.
                    </div>
                </CookieConsent>
            </div>
        </Router>
    );
}
