import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import s from './Checkbox.module.scss';

export default function Checkbox(props) {
    const {
        label, checked, onChange, id, name,
        disabled = false,
    } = props;
    return (
        <label htmlFor={id} className={s.Label}>
            <input
                id={id}
                className={classNames({
                    [s.Input]: true,
                })}
                disabled={disabled}
                type="checkbox"
                name={name}
                onChange={el => onChange(el)}
                checked={checked}
            />
            {label}
        </label>
    );
};

Checkbox.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
    onChange: () => null,
    label: null,
    checked: false,
    name: '',
    disabled: false,
};

