import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import s from './Button.module.scss'

export default function Button(props) {
    return (
        <button
            onClick={props.onClick}
            className={
                classNames({
                    [s.Button]: true,
                    [s.Middle]: props.middle,
                    [s.Big]: props.big,
                    [s.UltraBig]: props.ultraBig,
                    [s.Inverted]: props.inverted,
                }, props.className)
            }
        >
            {props.children}
        </button>
    );
}

Button.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.string]).isRequired,
    big: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    inverted: PropTypes.bool,
    middle: PropTypes.bool,
};

Button.defaultProps = {
    big: false,
    inverted: false,
    ultraBig: false,
    middle: false,
};