import config from "../constants";

export default function getMetro() {
    return new Promise((resolve, reject) => {
        fetch(`${config.API_URL}/api/v1/stations/search`, { method: 'GET' })
            .then(response => response.json())
            .then(response => resolve(
                Object.keys(response)
                    .map(k => (response[k]))
                    .map(v => ({ ...v, value: v.number }))
            ))
            .catch(err => reject(err));
    });
}